// Include alpine (tailwind stuff)
import Alpine from "alpinejs";
window.Alpine = Alpine;
Alpine.start();

// NProgress
import NProgress from "nprogress";
window.NProgress = NProgress;
NProgress.configure({
	showSpinner: false,
});
NProgress.start();
NProgress.set(0.4);
var interval = setInterval(function () {
	NProgress.inc();
}, 1000);
window.onload = function () {
	NProgress.done();
	clearInterval(interval);
};

// Add hit
function addHit(slug) {
	var xhttp = new XMLHttpRequest();
	xhttp.open("GET", "/news/hit/" + slug, true);
	xhttp.send();
}

// Back to top
const $backToTop = document.getElementById("back-to-top");
if ($backToTop) {
	$backToTop.addEventListener("click", (e) => {
		e.preventDefault();
		window.scroll({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	});
	window.addEventListener("scroll", () => {
		if (window.scrollY >= 400) {
			$backToTop.classList.remove("hidden");
			$backToTop.classList.add("block");
		} else {
			$backToTop.classList.remove("block");
			$backToTop.classList.add("hidden");
		}
	});
}

// Videos
const $videos = document.querySelectorAll(".video-player");
if ($videos) {
	$videos.forEach(($video) => {
		var videoUrl = $video.getAttribute("data-url");
		var imageUrl = $video.getAttribute("data-image");
		var slug = $video.getAttribute("data-slug");
		if (imageUrl) {
			$video.addEventListener("click", () => {
				$video.innerHTML =
					'<iframe class="h-full w-full" src="' +
					videoUrl +
					'?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
			});
		} else {
			$video.innerHTML =
				'<iframe class="h-full w-full" src="' +
				videoUrl +
				'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
		}
		$video.addEventListener("click", () => {
			if (slug != "undefined") {
				addHit(slug);
			}
		});
	});
}

// Podcasts
const $podcasts = document.querySelectorAll(".audio-player");
if ($podcasts) {
	$podcasts.forEach(($podcast) => {
		var $audioPlay = $podcast.querySelector(".audio-play");
		var $audioPause = $podcast.querySelector(".audio-pause");
		var $audioPlayer = $podcast.querySelector("audio");
		var slug = $podcast.getAttribute("data-slug");
		$podcast.addEventListener("click", () => {
			if ($audioPlayer.paused) {
				$audioPlayer.play();
				$audioPlay.classList.add("hidden");
				$audioPause.classList.remove("hidden");
			} else {
				$audioPlayer.pause();
				$audioPlay.classList.remove("hidden");
				$audioPause.classList.add("hidden");
			}
		});
		$audioPlayer.addEventListener("play", () => {
			if (slug != "undefined") {
				addHit(slug);
			}
		});
	});
}

// Feed items
const $feedItems = document.querySelectorAll(".feed-item .feed-item-body");
if ($feedItems) {
	$feedItems.forEach(($feedItem) => {
		$feedItem.addEventListener("click", (e) => {
			e.preventDefault();
			window.open($feedItem.dataset.url, "_blank");
		});
		$feedItem.querySelectorAll("a").forEach((el) => {
			el.addEventListener("click", (e) => {
				e.stopPropagation();
			});
		});
	});
}

// Copy to clipboard
const $copies = document.querySelectorAll(".feed-item-copy");
if ($copies) {
	$copies.forEach(($copy) => {
		$copy.addEventListener("click", (e) => {
			e.preventDefault();
			var $textarea = $copy.querySelector("textarea");
			$textarea.classList.remove("hidden");
			$textarea.select();
			document.execCommand("copy");
			$textarea.classList.add("hidden");
		});
	});
}
